// import cx from "classnames";
import classnames from "classnames/bind";

import styles from "./BackgroundGlow.module.css";
import {
  BackgroundGlowColor,
  BackgroundGlowProps,
} from "./BackgroundGlow.types";

const cx = classnames.bind(styles);

export default function BackgroundGlow({
  color = BackgroundGlowColor.PURPLE,
}: BackgroundGlowProps) {
  return (
    <div className={cx("glowContainer", `glowContainer--${color}`)}>
      <div className={styles.glow1} />
      <div className={styles.glow2} />
      <div className={styles.radar} />
    </div>
  );
}

export * from "./BackgroundGlow.types";
