import Head from "next/head";

export type MetaTagsProps = {
  title: string;
  description?: string;
  ogImage?: string;
  ogTitle?: string;
  twitterCard?: "summary_large_image" | "summary";
};

export default function MetaTags(props: MetaTagsProps) {
  const {
    title,
    description,
    ogTitle = title,
    ogImage,
    twitterCard = "summary_large_image",
  } = props;

  // These tags must be direct descendants of <Head />, with a few exceptions.
  // But for the sake of simplicity, just make them direct descendants.
  return (
    <Head>
      <title>{title}</title>
      {description ? <meta name="description" content={description} /> : null}

      {/* OpenGraph */}
      {ogImage ? <meta property="og:image" content={ogImage} /> : null}

      {/* Slack: ogTitle takes precendence over <title /> */}
      <meta property="og:title" content={ogTitle} />

      {description ? (
        <meta name="og:description" content={description} />
      ) : null}

      {/* https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/summary-card-with-large-image */}
      <meta property="twitter:card" content={twitterCard} />

      {/* More meta tags are in _document.tsx */}
    </Head>
  );
}
