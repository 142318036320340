import {
  Anchor,
  ButtonStyle,
  Carousel,
  Caps,
  DisplayText,
  Text,
  Color,
} from "@proofxyz/moonlight";
import cx from "classnames";
import Image from "next/image";
import Link from "next/link";

import { useDarkMode } from "src/hooks/useDarkMode";
import { type Signpost } from "src/server/contentful/signposts";

import styles from "./Signpost.module.css";

type SignpostsProps = {
  signposts: Signpost[];
};

type SignpostProps = {
  signpost: Signpost;
  imagePriority: boolean;
};

const Signpost: React.FunctionComponent<SignpostProps> = ({
  signpost,
  imagePriority,
}) => {
  const { mode } = useDarkMode();
  return (
    <div className={styles.Signpost}>
      <div className={styles.imageContainer}>
        <Image
          src={signpost.image.url}
          priority={imagePriority}
          alt={signpost.image.description}
          className={styles.image}
          layout="fill"
        />
      </div>

      <div className={styles.content}>
        <small className={cx(Caps.md, styles.kicker)}>{signpost.kicker}</small>
        <h2 className={cx(DisplayText.lg, DisplayText.bold, styles.headline)}>
          {signpost.headline}
        </h2>
        <Text size="lg" className={styles.description}>
          {signpost.description}
        </Text>
        <div className={cx(styles.cta, { invert: mode === "light" })}>
          <Link href={signpost.ctaPath} passHref>
            <Anchor size="md" style={ButtonStyle.primary} color={Color.neutral}>
              {signpost.ctaText}
            </Anchor>
          </Link>
        </div>
      </div>
    </div>
  );
};

const Signposts: React.FunctionComponent<SignpostsProps> = ({ signposts }) => {
  return (
    <Carousel>
      {signposts.map((signpost, index) => (
        <Signpost
          key={signpost.headline}
          signpost={signpost}
          imagePriority={index === 0}
        />
      ))}
    </Carousel>
  );
};

export default Signposts;
