import GrailsGlyph from "public/images/grails-glyph.svg";
import ArtistsGlyph from "public/images/homepage/artists-glyph.svg";
import CountdownGlyph from "public/images/homepage/countdown-glyph.svg";
import PodcastsGlyph from "public/images/homepage/podcasts-glyph.svg";
import MoonbirdsGlyph from "public/images/moonbirds-glyph.svg";
import ProofCollective from "public/images/proof-collective.svg";

export type EcosystemTile = {
  Logo: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  link: {
    text: string;
    href: string;
    newWindow?: boolean;
  };
};

export const ecosystemConfig: EcosystemTile[] = [
  {
    Logo: PodcastsGlyph,
    title: "PROOF Podcasts",
    description:
      "PROOF is your podcast for in-depth NFT coverage. Hosted by Kevin Rose.",
    link: {
      text: "Listen to our podcasts",
      href: "https://podcasts.proof.xyz",
      newWindow: true,
    },
  },
  {
    Logo: CountdownGlyph,
    title: "PROOF Daily NFT Countdown",
    description:
      "Daily market updates from our Director of Research Sam aka NFTStatistics.",
    link: {
      text: "Watch on Youtube",
      href: "https://www.youtube.com/playlist?list=PLsvBBjkB4w7b1rL4uzAqM894nzYxDc2-1",
    },
  },
  {
    Logo: ArtistsGlyph,
    title: "PROOF Artists",
    description:
      "Learn more about the incredible artists who have contributed to the PROOF ecosystem.",
    link: {
      text: "Browse artists",
      href: "/artists",
    },
  },
  {
    Logo: GrailsGlyph,
    title: "Grails",
    description:
      "PROOF-curated collections with artists revealed post-mint. Choose wisely.",
    link: {
      text: "Learn about Grails",
      href: "/grails/season-3",
    },
  },
  {
    Logo: ProofCollective,
    title: "PROOF Collective",
    description:
      "A private collective of 1,000 dedicated NFT collectors and artists.",
    link: {
      text: "Learn more",
      href: "https://proof.xyz/collective",
    },
  },
  {
    Logo: MoonbirdsGlyph,
    title: "Moonbirds",
    description:
      "Collection of 10,000 utility-enabled PFPs featuring a diverse pool of traits.",
    link: {
      text: "Learn more",
      href: "https://proof.xyz/moonbirds",
    },
  },
];
