import { DisplayText, Text } from "@proofxyz/moonlight";
import cx from "classnames";

import gridStyles from "src/styles/grid.module.css";

import styles from "./MissionStatement.module.css";

export default function MissionStatement() {
  return (
    <div className={cx(gridStyles.grid, styles.MissionStatement)}>
      <div className={cx(gridStyles.col, gridStyles.leftCol)}>
        <h1 className={DisplayText.lg}>
          Art is proof of our collective humanity
        </h1>

        <hr className={styles.shortRule} />
      </div>

      <div className={cx(gridStyles.col, gridStyles.rightCol, styles.intro)}>
        <Text size="xl" weight="300" color="secondary">
          From handprints on cave walls to pixels on screens, art forms the
          unbroken chain of our shared human experience. Art isn&rsquo;t
          frivolous, it&rsquo;s fundamental—the foundation to the stories we
          tell, cultures we create, and societies we build. But art is also at
          risk of being commodified.
        </Text>
        <Text size="xl" weight="300" color="secondary">
          The blockchain has the potential to usher in a digital art
          renaissance, but we have to decide: is it about the numbers or the
          people?
        </Text>
        <Text size="xl" weight="300" color="secondary">
          At PROOF, we&rsquo;re focused on creating unique experiences for
          collectors to connect with artists to own and champion their art. Art
          is more than a floor price—it&rsquo;s humanity&rsquo;s handprint on
          the world.
        </Text>
      </div>
    </div>
  );
}
