import cx from "classnames";

import styles from "./Content.module.css";
import type { ContentType } from "./Content.types";

const Content: ContentType = ({ children, className }) => {
  const classNames = cx(styles.Content, className);

  return <div className={classNames}>{children}</div>;
};

export default Content;
