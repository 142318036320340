import { Anchor, Color, Text } from "@proofxyz/moonlight";
import classnames from "classnames";
import Link from "next/link";

import {
  ecosystemConfig,
  type EcosystemTile,
} from "./EcosystemCallouts.config";
import styles from "./EcosystemCallouts.module.css";

const cx = classnames.bind(styles);

export default function EcosystemCallouts() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      {ecosystemConfig.map((tile) => (
        <CalloutTile key={tile.title} tile={tile} />
      ))}
    </div>
  );
}

const CalloutTile = ({ tile }: { tile: EcosystemTile }) => {
  const { Logo } = tile;

  return (
    <div
      className={cx(
        "pt-8 pb-10 px-2 sm:px-6 flex items-start dark:hover:bg-gray-100/5 hover:bg-purple-500/5 transition-colors duration-200 border-light-ui-primary dark:border-dark-ui-primary",
        styles.tile
      )}
    >
      <Logo className="w-16 flex-shrink-0 opacity-40 transition duration-200" />
      <div className="ml-6">
        <Text color="primary" className="mb-2" weight="semibold">
          {tile.title}
        </Text>
        <Text
          color="secondary"
          className="mb-4 transition-colors duration-200"
          weight="light"
        >
          {tile.description}
        </Text>

        <Link href={tile.link.href} passHref>
          <Anchor
            color={Color.purple}
            {...(tile.link.newWindow
              ? { target: "_blank", rel: "noreferrer" }
              : {})}
          >
            {tile.link.text}
          </Anchor>
        </Link>
      </div>
    </div>
  );
};
