import { type NextPage, GetStaticProps } from "next";

import Content from "src/components/Content";
import Layout from "src/components/Layout";
import MetaTags from "src/components/MetaTags";
import Signposts from "src/components/Signposts";
import BackgroundGlow from "src/components/home/BackgroundGlow";
import EcosystemCallouts from "src/components/home/EcosystemCallouts";
import MissionStatement from "src/components/home/MissionStatement";
import {
  getSignpostsByPath,
  type Signpost,
} from "src/server/contentful/signposts";

import styles from "./index.module.css";

type HomePageProps = {
  signposts: Signpost[];
};

const HomePage: NextPage<HomePageProps> = ({ signposts }) => {
  return (
    <div>
      <MetaTags
        title="Homepage | PROOF"
        description="Learn more about the community-centric web3 products being built to celebrate art, connect collectors, and activate creative entrepreneurship."
        ogImage="https://proof-xyz.imgix.net/banners/default.png?w=1200&h=627&fm=jpg&fit=crop&auto=format&q=55&usm=12"
      />

      <Layout>
        <BackgroundGlow />

        <Content className={styles.HomePage__Content}>
          {/* relative is required to make the big glow go behind the content */}
          <div className="relative flex flex-col gap-16 sm:gap-[120px]">
            <div className={styles.HomePage__Signposts}>
              <Signposts signposts={signposts} />
            </div>
            <EcosystemCallouts />
            <MissionStatement />
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default HomePage;

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const path = "/";
  const signposts = await getSignpostsByPath(path);

  return {
    props: {
      signposts,
    },
  };
};
